<template>
  <div
    class="login"
    :style="
      `background:url(${common.getRes(
        'loginbg_new.png'
      )});background-size:100%;`
    "
  >
    <div id="lang">
      <img class="lang-img" @click="toggleLanguage" :src="lang.langBtn" />
    </div>
    <div class="logo">
      <van-row>
        <van-col offset="4" span="16">
          <img class="logo-img" :src="common.getRes('logo.png')" />
        </van-col>
      </van-row>
    </div>

    <div class="form">
      <van-row>
        <van-col offset="1" span="22">
          <div class="form-input">
            <input v-model="form.user" :placeholder="lang.account_number" />
            <div
              class="form-input-icon"
              v-show="form.user.length > 0"
              @click="clearUser"
            >
              <img :src="common.getRes('clear.png')" />
            </div>
          </div>
        </van-col>
      </van-row>
      <van-row>
        <van-col offset="1" span="22">
          <div class="form-input" style="margin-top: 10px">
            <input
              v-model="form.password"
              :placeholder="lang.password"
              autocomplete="off"
              :type="showPassword ? 'text' : 'password'"
            />
            <div class="form-input-icon" @click="taggleShowPassword">
              <img
                :src="
                  common.getRes(showPassword ? 'eye-open.png' : 'eye-close.png')
                "
              />
            </div>
          </div>
        </van-col>
      </van-row>
    </div>

    <div style="margin-top: 50px">
      <van-row>
        <van-col offset="1" span="21">
          <div class="form-button" @click="submit">{{ lang.sign_in }}</div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import Common from "@/library/Common";
import { useRouter } from "vue-router";
import Validation from "@/library/Validation";
import request from "@/library/MyRequest";
import { Toast } from "vant";
import "@/css/form.css";

export default {
  name: "login",
  setup() {
    const router = useRouter();
    const common = Common;
    let state = reactive({
      lang: {
        account_number: "",
        password: "",
        sign_in: "",
        langBtn: "",
        userNull: "",
        passwordNull: "",
        accountNumberOrPasswordError: "",
        telphoneError: "",
        submit_type_success: "",
      },
      form: {
        user: "", //13450286220
        password: "", //forking1234
      },
      showPassword: false,
    });
    common.initLanguage(state.lang);
    state.lang.langBtn = common.getLanguageBtn();
    const toggleLanguage = function() {
      common.toggleLanguage();
      common.initLanguage(state.lang);
      state.lang.langBtn = common.getLanguageBtn();
    };

    const submit = async () => {
      let rules = [
        {
          label: "user",
          ruleValidate: [
            `required|message:${state.lang.userNull}`,
            `phone|message:${state.lang.telphoneError}`,
          ],
        },
        {
          label: "password",
          ruleValidate: [`required|message:${state.lang.passwordNull}`],
        },
      ];
      var form = Validation.validate(state.form, rules);
      var error = Validation.getFirstError(form);
      if (error != null) {
        Toast(error);
        return;
      }

      var send = {
        phone_number: state.form.user,
        password: state.form.password,
      };
      try {
        const res = await request.loginpost(`/teacher/signin/`, send);
        if (res.data.token.length > 0) {
          common.setLocal("token", res.data.token);
          common.setLocal("phone_number", state.form.user);
          common.setLocal("password", state.form.password);
          Toast(state.lang.submit_type_success);
          setTimeout(async () => {
            let teacherInfo = await request.get(`/teacher/`);
            delete teacherInfo["data"]["created_at"];
            delete teacherInfo["data"]["updated_at"];
            common.setLocal("teacherInfo", JSON.stringify(teacherInfo.data));
            console.log("teacherInfo", teacherInfo.data);
            router.push({ name: "home" });
          }, 500);
        }
      } catch (e) {
        Toast(state.lang.accountNumberOrPasswordError);
      }
    };

    const clearUser = function() {
      state.form.user = "";
    };

    const taggleShowPassword = function() {
      state.showPassword = !state.showPassword;
    };

    const hasLogin = function() {
      var token = common.getLocal("token");
      var phone_number = common.getLocal("phone_number");
      var password = common.getLocal("password");
      if (token && phone_number && password) {
        state.form.user = phone_number;
        state.form.password = password;
        submit();
      }
    };

    hasLogin();
    // toggleLanguage();
    return {
      ...toRefs(state),
      common,
      toggleLanguage,
      submit,
      clearUser,
      taggleShowPassword,
    };
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
}
.logo {
  padding-top: 230px;
}
.logo-img {
  width: 100%;
}
.form {
  margin-top: 150px;
}
</style>
